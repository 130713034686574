import React, { useEffect, useState } from 'react';
import './App.css';
import EditorComponent from './Editor/Editor';

function App() {
  const [darkMode, setDarkMode] = useState(false); // State to track dark mode

  useEffect(() => {
    const handleDarkModeChange = () => {
      const storedMode = localStorage.getItem('darkMode') || 'normal';
      setDarkMode(storedMode);
    };
  
    window.addEventListener('darkModeChange', handleDarkModeChange);
  
    // Initial check to apply the current theme
    handleDarkModeChange();
  
    return () => {
      window.removeEventListener('darkModeChange', handleDarkModeChange);
    };
  }, []);
  


  return (
    <div className={`App ${darkMode}`}>
      <header className="App-header">
        <div className='guidedpencontainer' id='guidedpencontainer' style={{}}>
           <EditorComponent /> 
        </div>
      </header>
    </div>
  );
}

export default App;
