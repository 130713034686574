import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('option1');
  const [price, setPrice] = useState(3900);

  // Update pricing based on the selected option
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    switch (event.target.value) {
      case 'option1':
        setPrice(3900); // $39 in cents
        break;
      case 'option2':
        setPrice(8900); // $59 in cents
        break;
      case 'option3':
        setPrice(15900); // $199 in cents
        break;
      case 'option4':
        setPrice(22700); // $299 in cents
        break;
      default:
        setPrice(3900); // Default price
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
      },
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      const WorksIdentifier = localStorage.getItem('works');
      const WorksTitle = 'My Book'; // You may retrieve this from a form or another part of your application
      
      // Here you can call your backend endpoint to process the payment method...
      const response = await fetch('http://guidedpen.com/API-GuidedPen/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          email: email,
          name: name,
          works: 'hardcoded atm',
          WorksIdentifier: WorksIdentifier,
          WorksTitle: WorksTitle,
          price: price,
        }),
      });
      
      const paymentIntentResponse = await response.json();
      
      if (paymentIntentResponse.success) {
        console.log('Payment successful:', paymentIntentResponse);
        setPaymentSuccess(true);
        localStorage.setItem('Auth', paymentIntentResponse.auth);
        localStorage.setItem('Email', paymentIntentResponse.email);
      
        setTimeout(() => {
          window.location.reload();
        }, 7500);
      } else {
        console.error('Payment failed:', paymentIntentResponse.error);
      }
    }
  };

  const readablePrice = `$${(price / 100).toFixed(2)}/mo`;

  return (
    <form onSubmit={handleSubmit}>
      {!paymentSuccess ? (
        <>
          <h2 className="paymentheader">Unlock GuidedPen</h2>
          <span className="paymentbody">...and elevate your writing to new heights!</span>
          <br />
          <div className="selectOptionContainer" style={{ marginTop: '15px' }}>
            <select className="selectOption" value={selectedOption} onChange={handleOptionChange}>
              <option value="option1">Occasional Writer - 15k generated words</option>
              <option value="option2">Regular Writer - 50k generated words</option>
              <option value="option3">Frequent Writer - 100k generated words</option>
              <option value="option4">Pro Writer - 150k generated words</option>
            </select>
          </div>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="cardInputs"
          />
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="cardInputs"
          />
          <div style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: '#fff', marginTop: '15px' }}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
          <div className="paymentprice">{readablePrice}</div>
          <button type="submit" disabled={!stripe || paymentSuccess} className="paymentButton">
            Yes! I'm in
          </button>
        </>
      ) : (
        <div className="successMessage">
          <p>Success! We're thrilled to have you on board and can't wait to see where your Guided Pen takes you. Welcome to the journey!</p>
          <br />
          <br />
          Now logging you in...
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
